// JavaScript Document

(function() {
  "use strict";

const exampleModal = document.getElementById('sitemodal');
exampleModal.addEventListener('show.bs.modal', event => {

  const button = event.relatedTarget;
  const recipient = button.getAttribute('data-bs-whatever');
    
  let APP_TOKEN = get('spaneldump', 'APP_TOKEN');    

    const httpRequest = new XMLHttpRequest();
    httpRequest.open('GET', recipient, true);
    httpRequest.setRequestHeader("Content-Type", "text/plain;charset=UTF-8");
    httpRequest.setRequestHeader('APP_TOKEN', APP_TOKEN);
    httpRequest.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    httpRequest.send();
    httpRequest.onreadystatechange = function () {
          
    if (httpRequest.readyState != 4 || httpRequest.status != 200) return;
        
        const modalBodyInput = exampleModal.querySelector('.modal-body')
      
        modalBodyInput.innerHTML  = httpRequest.responseText;

        VMasker(document.getElementById("celular")).maskPattern('(99) 99999-9999');
        
        validation();

 
    }
});
    
var validation = function(){

        const fv = FormValidation.formValidation(document.getElementById('form1'), {
                    fields: {
                        nome_comp: {
                            validators: {
                                notEmpty: {
                                    message: 'Nome completo requerido!',
                                },
                                regexp: {
                                    regexp: /^[a-zA-Z\s]+$/,
                                    message: 'O nome só pode consistir em letras alfabéticas e espaço',
                                },
                            },
                        },

                        celular: {
                            validators: {
                                notEmpty: {
                                    message: 'Número do celular requerido!',
                                },
                            },
                        },

                        cad_email: {
                            validators: {
                                emailAddress: {
                                    message: 'Digite um e-mail válido', 
                                },
                                notEmpty: {
                                    message: 'Email requerido!', 
                                },
                            },
                        },
                        message: {
                            validators: {
                                callback: {
                                    message: 'Digite sua mensagem!',
                                    callback: function (value) {
                                        // Get the plain text without HTML
                                        const text = tinyMCE.activeEditor.getContent({
                                            format: 'text',
                                        });

                                        return text.length >= 25;
                                    },
                                },
                            },
                        },
                    },
                    plugins: {
                        trigger: new FormValidation.plugins.Trigger(),
                        bootstrap5: new FormValidation.plugins.Bootstrap5(),
                        submitButton: new FormValidation.plugins.SubmitButton(),
                        icon: new FormValidation.plugins.Icon({
                            valid: 'fa fa-check',
                            invalid: 'fa fa-times',
                            validating: 'fa fa-refresh',
                        }),
                    },
                });
    
fv.on('core.form.valid', function(event) {
    
 /* SUBMIT FORM */
    const btSubmit = document.getElementById("submit");
    
    tinyMCE.get('message').save(); 
    
    btSubmit.innerHTML = '<i class="fa-solid fa-spinner fa-spin-pulse"></i>';

    const formElem = document.getElementById('form1');
    
    const data = new FormData(formElem);

    let APP_TOKEN = get('spaneldump', 'APP_TOKEN'); 

    const request = new XMLHttpRequest();
    request.open("POST", "painel-do-aluno/serverside/enviar-contato.php");
    request.setRequestHeader('APP_TOKEN', APP_TOKEN);
    request.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    request.send(data);
    request.addEventListener("load", function () {
        
        const messages = document.getElementById("modal-body");

        if (request.readyState === 4 && request.status === 200) {

            messages.innerHTML = request.responseText;
            
            btSubmit.disabled = true;
            
            btSubmit.innerHTML = '<i class="bx bxs-error bx-flashing bx-md"></i> OK!';

        } else { 
            
            console.log("error. see details below.");
            console.log(request.status + ": " + request.responseText);
            messages.innerHTML += "An error occured, please try again.";
            
        }
    }); 
});

                tinyMCE.init({
                    selector: 'textarea',
                    menubar: false,
                    setup: function (editor) {
                        editor.on('keyup', function () {
                            // Revalidate the comment field
                            fv.revalidateField('message');
                        });
                    },
                    
                });    
        
};
    


})();